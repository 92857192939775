// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-searchbar {
  text-align: start;
  vertical-align: middle;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
}

ion-badge {
  --background: transparent !important;
  font-weight: bold;
  font-size: 14px;
  color: var(--ion-color-dark) !important;
  padding: 0px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImZvcm0tbW9kYWwuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxpQkFBQTtFQUNBLHNCQUFBO0VBQ0EsbUJBQUE7RUFDQSxnQkFBQTtFQUNBLG1CQUFBO0FBQ0o7O0FBQ0E7RUFDSSxvQ0FBQTtFQUNBLGlCQUFBO0VBQ0EsZUFBQTtFQUNBLHVDQUFBO0VBQ0EsWUFBQTtBQUVKIiwiZmlsZSI6ImZvcm0tbW9kYWwuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJpb24tc2VhcmNoYmFyIHtcbiAgICB0ZXh0LWFsaWduOiBzdGFydDtcbiAgICB2ZXJ0aWNhbC1hbGlnbjogbWlkZGxlO1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgcGFkZGluZy10b3A6IDZweDtcbiAgICBwYWRkaW5nLWJvdHRvbTogNnB4O1xufVxuaW9uLWJhZGdlIHtcbiAgICAtLWJhY2tncm91bmQ6IHRyYW5zcGFyZW50ICFpbXBvcnRhbnQ7XG4gICAgZm9udC13ZWlnaHQ6IGJvbGQ7XG4gICAgZm9udC1zaXplOiAxNHB4O1xuICAgIGNvbG9yOiB2YXIoLS1pb24tY29sb3ItZGFyaykgIWltcG9ydGFudDtcbiAgICBwYWRkaW5nOiAwcHg7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/pages/general/form-modal/form-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;;AACA;EACI,oCAAA;EACA,iBAAA;EACA,eAAA;EACA,uCAAA;EACA,YAAA;AAEJ;AACA,4zBAA4zB","sourcesContent":["ion-searchbar {\n    text-align: start;\n    vertical-align: middle;\n    align-items: center;\n    padding-top: 6px;\n    padding-bottom: 6px;\n}\nion-badge {\n    --background: transparent !important;\n    font-weight: bold;\n    font-size: 14px;\n    color: var(--ion-color-dark) !important;\n    padding: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
