import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private documentsFormRefreshAnnouncedSource = new Subject<void>();
  private masterDataFormRefreshAnnouncedSource = new Subject<void>();
  private documentsScrollToSource = new Subject<void>();
  private masterDataScrollToSource = new Subject<void>();
  private calendarRefreshAnnouncedSource = new Subject<void>();
  documentsFormRefreshSource$ = this.documentsFormRefreshAnnouncedSource.asObservable();
  masterDataFormRefreshSource$ = this.masterDataFormRefreshAnnouncedSource.asObservable();
  documentsScrollToSource$ = this.documentsScrollToSource.asObservable();
  masterDataScrollToSource$ = this.masterDataScrollToSource.asObservable();
  calendarRefreshAnnouncedSource$ = this.calendarRefreshAnnouncedSource.asObservable();

  constructor() { }

  public publishDocumentsFormRefresh(): void {
    this.documentsFormRefreshAnnouncedSource.next();
  }

  public publishMasterDataFormRefresh(): void {
    this.masterDataFormRefreshAnnouncedSource.next();
  }

  public publishCalendarRefresh(): void {
    this.calendarRefreshAnnouncedSource.next();
  }

  public documentsScrollTo(): void {
    this.documentsScrollToSource.next();
  }

  public masterDataScrollTo(): void {
    this.masterDataScrollToSource.next();
  }
}
