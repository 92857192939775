// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-select {
  max-width: 100%;
  width: 100%;
}

.item-select ion-label {
  max-width: 75px;
  min-width: 75px;
}

#select-option {
  margin-right: auto;
  width: 100%;
}

ion-item {
  --border-width: 0px !important;
}

ion-item-divider {
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: transparent !important;
  height: 38px;
}

.datetime {
  --padding-start: 0px !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImZpbHRlci1wb3BvdmVyLnBhZ2Uuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGVBQUE7RUFDQSxXQUFBO0FBQ0Y7O0FBQ0E7RUFDRSxlQUFBO0VBQ0EsZUFBQTtBQUVGOztBQUFBO0VBQ0Usa0JBQUE7RUFDQSxXQUFBO0FBR0Y7O0FBREE7RUFDRSw4QkFBQTtBQUlGOztBQUZBO0VBQ0UsZUFBQTtFQUNBLG1CQUFBO0VBQ0EseUJBQUE7RUFDQSx3Q0FBQTtFQUNBLFlBQUE7QUFLRjs7QUFIQTtFQUNFLCtCQUFBO0FBTUYiLCJmaWxlIjoiZmlsdGVyLXBvcG92ZXIucGFnZS5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaW9uLXNlbGVjdCB7XG4gIG1heC13aWR0aDogMTAwJTtcbiAgd2lkdGg6IDEwMCU7XG59XG4uaXRlbS1zZWxlY3QgaW9uLWxhYmVsIHtcbiAgbWF4LXdpZHRoOiA3NXB4O1xuICBtaW4td2lkdGg6IDc1cHg7XG59XG4jc2VsZWN0LW9wdGlvbiB7XG4gIG1hcmdpbi1yaWdodDogYXV0bztcbiAgd2lkdGg6IDEwMCU7XG59XG5pb24taXRlbSB7XG4gIC0tYm9yZGVyLXdpZHRoOiAwcHggIWltcG9ydGFudDtcbn1cbmlvbi1pdGVtLWRpdmlkZXIge1xuICBmb250LXNpemU6IDEycHg7XG4gIGxldHRlci1zcGFjaW5nOiAxcHg7XG4gIHRleHQtdHJhbnNmb3JtOiB1cHBlcmNhc2U7XG4gIGJhY2tncm91bmQtY29sb3I6IHRyYW5zcGFyZW50ICFpbXBvcnRhbnQ7XG4gIGhlaWdodDogMzhweDtcbn1cbi5kYXRldGltZSB7XG4gIC0tcGFkZGluZy1zdGFydDogMHB4ICFpbXBvcnRhbnQ7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/pages/general/filter-popover/filter-popover.page.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;AACF;;AACA;EACE,eAAA;EACA,eAAA;AAEF;;AAAA;EACE,kBAAA;EACA,WAAA;AAGF;;AADA;EACE,8BAAA;AAIF;;AAFA;EACE,eAAA;EACA,mBAAA;EACA,yBAAA;EACA,wCAAA;EACA,YAAA;AAKF;;AAHA;EACE,+BAAA;AAMF;AACA,4jCAA4jC","sourcesContent":["ion-select {\n  max-width: 100%;\n  width: 100%;\n}\n.item-select ion-label {\n  max-width: 75px;\n  min-width: 75px;\n}\n#select-option {\n  margin-right: auto;\n  width: 100%;\n}\nion-item {\n  --border-width: 0px !important;\n}\nion-item-divider {\n  font-size: 12px;\n  letter-spacing: 1px;\n  text-transform: uppercase;\n  background-color: transparent !important;\n  height: 38px;\n}\n.datetime {\n  --padding-start: 0px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
