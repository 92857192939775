import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
import { Utils } from '../utils/utils';
import { Timestamp } from '@angular/fire/firestore';
import { Form } from '../interfaces/config/form';
import { MasterDataObj } from '../interfaces/config/masterdata';
import { ConfigService } from './config.service';

const blacklist: Array<string> = ['$key', 'type', 'subType', 'status', 'client', 'number', 'creator', 'holder', 'partner', 'userId', 'currency', 'email'];
const imageBlacklist: Array<string> = ['data:image/jpeg;base64,', 'data:image/png;base64,', 'data:application/pdf;base64,', 'https://firebasestorage.googleapis.com/'];

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  private password: string;
  private key: string;
  private encryptionIV: string;

  constructor(
    private configService: ConfigService
  ) { }

  public async encryptObj(obj: object, mapping?: Form | MasterDataObj): Promise<object> {
    let encryptedObj: object = {};

    await Utils.asyncForEach(Object.keys(obj), async (key: string) => {
      if (typeof obj[key] === 'string' && obj[key] !== ''
        && !blacklist.includes(key) && !imageBlacklist.some((blacklistItem) => obj[key].includes(blacklistItem))) {
        var mappingAttribute = mapping?.attributes.find(a => a.controls.some(c => c.key === key));
        var attributeControl = mappingAttribute?.controls.find(c => c.key === key);
        if (
          !key.startsWith('issuer_')
          && !key.startsWith('recipient_')
          && ((mapping && attributeControl?.encryption === true) || !mapping)) {
          encryptedObj[key] = this.encryptValue(obj[key]);
        } else if (key.startsWith('issuer_') && mapping && mapping['issuer'] !== undefined) {
          var encryption = (mapping as Form).issuer.encryption;
          var dynamicControl = (mapping as Form).issuer.controls?.find(c => c.key === key);
          if (encryption && (!dynamicControl || dynamicControl && dynamicControl.encryption)) {
            encryptedObj[key] = this.encryptValue(obj[key]);
          } else {
            encryptedObj[key] = obj[key];
          }
        } else if (key.startsWith('recipient_') && mapping && mapping['recipient'] !== undefined) {
          var encryption = (mapping as Form).recipient.encryption;
          var dynamicControl = (mapping as Form).recipient.controls?.find(c => c.key === key);
          if (encryption && (!dynamicControl || dynamicControl && dynamicControl.encryption)) {
            encryptedObj[key] = this.encryptValue(obj[key]);
          } else {
            encryptedObj[key] = obj[key];
          }
        } else {
          encryptedObj[key] = obj[key];
        }
      } else {
        encryptedObj[key] = obj[key];
      }
    });

    return encryptedObj;
  }

  public async decryptObj(obj: object): Promise<void> {
    if (!this.configService.getClientMapping()) {
      return;
    }

    await Utils.asyncForEach(Object.keys(obj), async (key: string) => {
      if (typeof obj[key] === 'string' && obj[key] !== '' && !blacklist.includes(key) && !blacklist.includes(key)
        && !imageBlacklist.some((blacklistItem) => obj[key].includes(blacklistItem))) {
        obj[key] = this.decryptValue(obj[key]);
      }
    });
  }

  public encryptValue(value: string): string {
    this.setSecrets();

    if (!this.password) {
      return value;
    } else {
      if (this.alreadyEncrypted(value)) {
        return value;
      } else {
        let parsedKey = CryptoJS.enc.Utf8.parse(this.key);
        let parsedIv = CryptoJS.enc.Utf8.parse(this.encryptionIV);

        let algo = CryptoJS.algo.AES.createEncryptor(parsedKey, {
          iv: parsedIv,
          mode: CryptoJS.mode.CTR,
          padding: CryptoJS.pad.NoPadding
        });
        algo.process(CryptoJS.enc.Utf8.parse(value));
        let result = algo.finalize().toString(CryptoJS.enc.Hex);

        return Buffer.from(
          result
        ).toString('base64');
      }
    }
  }

  public decryptValue(value: string): string {
    this.setSecrets();

    if (!this.password) {
      return value;
    } else {
      let parsedKey = CryptoJS.enc.Utf8.parse(this.key);
      let parsedIv = CryptoJS.enc.Utf8.parse(this.encryptionIV);

      const buff = Buffer.from(value, 'base64');

      try {
        let algo = CryptoJS.algo.AES.createDecryptor(parsedKey, {
          iv: parsedIv,
          mode: CryptoJS.mode.CTR,
          padding: CryptoJS.pad.NoPadding
        });
        algo.process(CryptoJS.enc.Hex.parse(buff.toString('utf8')));
        let decrypted = algo.finalize().toString(CryptoJS.enc.Utf8);

        return decrypted;
      } catch {
        return value;
      }
    }
  }

  private setSecrets(): void {
    if (this.password && this.key && this.encryptionIV) {
      return;
    } else {
      this.password = this.getPassword();
      if (this.password !== null) {
        this.key = this.getKey(this.password);
        this.encryptionIV = this.getIv(this.password);
      }
    }
  }

  private getPassword(): string {
    var clientMapping = this.configService.getClientMapping();

    if (!clientMapping?.hashKey || !clientMapping?.name) {
      return null;
    }

    return clientMapping.hashKey + clientMapping.name;
  }

  private getKey(password: string): string {
    return CryptoJS.SHA512(password).toString(CryptoJS.enc.Hex).substring(0, 32);
  }

  private getIv(password: string): string {
    return CryptoJS.SHA512(password).toString(CryptoJS.enc.Hex).substring(0, 16);
  }

  private async alreadyEncrypted(value: string): Promise<boolean> {
    try {
      CryptoJS.enc.Base64.parse(value).toString(CryptoJS.enc.Utf8);
      return true;
    } catch {
      return false;
    }
  }
}
