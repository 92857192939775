import { Component } from '@angular/core';
import {
  Platform, AlertController, LoadingController, ModalController, NavParams
} from '@ionic/angular';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../../services/storage.service';
import { AuthService } from '../../auth/auth.service';
import { AccountService } from '../../account/account.service';
import { FormService } from '../../documents/form/form.service';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { DateHelpers } from '../../../utils/date-helpers';
import { Utils } from '../../../utils/utils';
import { ModalOptions } from '@ionic/core';
import { ImageModalComponent } from '../image-modal/image-modal.component';
import { Browser } from '@capacitor/browser';
import { ImageService } from '../../../services/image.service';
import { Form, FormAttributeControl } from '../../../interfaces/config/form';
import { ControlType } from '../../../enums/mappings/control-type.enum';
import { InputType } from '../../../enums/mappings/input-type.enum';
import { AmountHelpers } from 'src/app/utils/amount-helpers';
import * as formularParser from 'hot-formula-parser';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { format } from 'date-fns';
import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';
import { PhotoViewer, capShowOptions } from '@capacitor-community/photoviewer';
import { AlertId } from '../../../../app/enums/alert-id.enum';
import { Performance, PerformanceTrace, trace } from '@angular/fire/performance';
import { Analytics, logEvent } from '@angular/fire/analytics';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  private pageTrace: PerformanceTrace;

  public directory: Directory;
  public darkMode: boolean;
  public modalForm: UntypedFormGroup;
  public formMapping: Form;
  public formControls: Array<FormAttributeControl>;
  public controlType: typeof ControlType = ControlType;
  public inputType: typeof InputType = InputType;
  public maxImages: number;
  public currentImageCount: number;
  public controlMapping: FormAttributeControl;
  public existingControl: AbstractControl;
  public existingItemIndex: number;
  public currency: string;
  public language: any;
  public net: string;
  public gross: string;
  public vat: string;
  private parser: any;

  constructor(
    public translate: TranslateService,
    public platform: Platform,
    public authService: AuthService,
    public accountService: AccountService,
    public formService: FormService,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    public formBuilder: UntypedFormBuilder,
    private imageService: ImageService,
    private navParams: NavParams,
    private storageService: StorageService,
    private performance: Performance,
    private analytics: Analytics
  ) {
    this.pageTrace = trace(this.performance, ModalComponent.name);

    this.modalForm = formBuilder.group({});

    this.parser = new formularParser.Parser();
  }

  public async ionViewWillEnter(): Promise<void> {
    await this.storageService.set('component', ModalComponent.name);
    logEvent(this.analytics, 'screen_view');
    try { this.pageTrace.start(); } catch (error) { }

    var language = await this.storageService.get('language');
    this.language = language.replace('_', '-');

    var currency = await this.storageService.get('currency');
    if (currency !== null) {
      this.currency = currency;
    } else {
      this.currency = 'EUR';
    }

    var darkMode = await this.storageService.get('darkMode');
    this.darkMode = darkMode;

    this.controlMapping = this.navParams.data.controlMapping;
    this.existingControl = this.navParams.data.existingControl;
    this.existingItemIndex = this.navParams.data.existingItemIndex;
    this.maxImages = this.navParams.data.maxImages;
    this.currentImageCount = this.navParams.data.currentImageCount;

    this.createControls();

    if (this.existingControl) {
      Object.keys(this.existingControl?.value[this.existingItemIndex]).forEach((key: string) => {
        var formControl = this.controlMapping.modalControls.filter((f: FormAttributeControl) => f.key === key).pop();
        if (formControl && formControl.type === ControlType.Input && formControl.options.type === InputType.Date && typeof this.existingControl.value[this.existingItemIndex][key] === 'object') {
          this.modalForm.controls[key].setValue(DateHelpers.getCalenderPickerDate(this.existingControl.value[this.existingItemIndex][key]));
        } else if (formControl && formControl.type === ControlType.Input && formControl.options.type === InputType.Datetime && typeof this.existingControl.value[this.existingItemIndex][key] === 'object') {
          this.modalForm.controls[key].setValue(DateHelpers.getCalenderPickerDatetime(this.existingControl.value[this.existingItemIndex][key]));
        } else if (formControl && formControl.type === ControlType.Input && formControl.options.type === InputType.Month && typeof this.existingControl.value[this.existingItemIndex][key] === 'object') {
          this.modalForm.controls[key].setValue(DateHelpers.getCalenderPickerMonthYear(this.existingControl.value[this.existingItemIndex][key]));
        } else if (formControl && formControl.type === ControlType.Input && formControl.options.type === InputType.Year && typeof this.existingControl.value[this.existingItemIndex][key] === 'object') {
          this.modalForm.controls[key].setValue(DateHelpers.getCalenderPickerYear(this.existingControl.value[this.existingItemIndex][key]));
        } else if (formControl && formControl.type === ControlType.Input && formControl.options.type === InputType.Time && typeof this.existingControl.value[this.existingItemIndex][key] === 'object') {
          this.modalForm.controls[key].setValue(DateHelpers.getCalenderPickerTime(this.existingControl.value[this.existingItemIndex][key]));
        } else {
          this.modalForm.controls[key].setValue(this.existingControl.value[this.existingItemIndex][key]);
        }
      });
    }

    this.calculate();
  }

  public async ionViewWillLeave(): Promise<void> {
    try { this.pageTrace.stop(); } catch (error) { }
  }

  public async save(): Promise<void> {
    if (this.modalForm.valid) {
      var controlsMap = new Map<string, AbstractControl>();

      Object.keys(this.modalForm.controls).forEach((key: string) => {
        var formControl = this.controlMapping.modalControls.filter((f: FormAttributeControl) => f.key === key).pop();
        if (formControl && formControl.type === ControlType.Input && (formControl.options.type === InputType.Amount || formControl.options.type === InputType.Number)
          && typeof this.modalForm.controls[key].value === 'string') {
          this.modalForm.controls[key].setValue(parseFloat(this.modalForm.controls[key].value.replace(',', '.')));
        } else if (formControl && formControl.type === ControlType.Input
          && (formControl.options.type === InputType.Date || formControl.options.type === InputType.Datetime || formControl.options.type === InputType.Month
            || formControl.options.type === InputType.Year)
          && typeof this.modalForm.controls[key].value === 'string') {
          this.modalForm.controls[key].setValue(DateHelpers.getFirestoreTimestamp(this.modalForm.controls[key].value));
        }
        controlsMap.set(key, this.modalForm.controls[key]);
      });
      await this.modalCtrl.dismiss(controlsMap);
    } else {
      let header: string, message: string, ok: string;
      this.translate.get('TITLE_INCOMPLETE').subscribe((result: string) => header = result);
      this.translate.get('MSG_INCOMPLETE').subscribe((result: string) => message = result);
      this.translate.get('OK').subscribe((result: string) => ok = result);
      var alert = await this.alertCtrl.create({
        id: AlertId.Incomplete,
        header,
        message,
        buttons: [
          {
            text: ok
          }
        ]
      })
      await alert.present();
      Object.keys(this.modalForm.controls).forEach(key => {
        this.modalForm.controls[key].markAsDirty();
      });
    }
  }

  public async addImage(): Promise<void> {
    let selection: string, viewPhoto: string, choosePhoto: string, takePicture: string, cancel: string, ok: string;
    this.translate.get('HOM_SEL_SELECT').subscribe((result: string) => selection = result);
    this.translate.get('AUT_PRO_VIEWPICTURE').subscribe((result: string) => viewPhoto = result);
    this.translate.get('AUT_PRO_CHOOSEPHOTO').subscribe((result: string) => choosePhoto = result);
    this.translate.get('AUT_PRO_TAKEPICTURE').subscribe((result: string) => takePicture = result);
    this.translate.get('OK').subscribe((result: string) => ok = result);
    this.translate.get('BTN_CANCEL').subscribe((result: string) => cancel = result);

    const result = await ActionSheet.showActions({
      title: selection,
      options: [
        {
          title: viewPhoto,
        },
        {
          title: choosePhoto,
        },
        {
          title: takePicture
        },
        {
          title: cancel,
          style: ActionSheetButtonStyle.Destructive,
        },
      ],
    });

    if (result.index === 0) {
      var image = this.modalForm.get(ControlType.Image).value;
      if (image) {
        this.showImage();
      } else {
        let header: string, message: string;
        this.translate.get('TITLE_WARNING').subscribe((result: string) => header = result);
        this.translate.get('ARC_EDI_MESSAGE').subscribe((result: string) => message = result);
        var alert = await this.alertCtrl.create({
          id: AlertId.NoImage,
          header,
          message,
          buttons: [
            {
              text: ok
            }
          ]
        });
        await alert.present();
      }
    } else if (result.index === 1) {
      if (this.currentImageCount < this.maxImages) {
        await this.takePicture(CameraSource.Photos);
      } else {
        this.showMaxImageAlert();
      }
    } else if (result.index === 2) {
      if (this.currentImageCount < this.maxImages) {
        await this.takePicture(CameraSource.Camera);
      } else {
        this.showMaxImageAlert();
      }
    } else {
      return;
    }
  }

  public async takePicture(source: CameraSource) {
    const image = await Camera.getPhoto({
      quality: this.imageService.getImageQuality(),
      allowEditing: false,
      resultType: CameraResultType.Base64,
      saveToGallery: false,
      correctOrientation: true,
      source
    });

    var imageData = image.base64String;
    let base64Image = null;
    base64Image = 'data:image/jpeg;base64,' + imageData;
    this.modalForm.get(ControlType.Image).setValue(base64Image);
  }

  public deleteImage(): void {
    this.modalForm.get(ControlType.Image).setValue(null);
  }

  public async cancel(): Promise<void> {
    await this.modalCtrl.dismiss();
  }

  public toUpperCase(text: string): string {
    return text.toUpperCase();
  }

  public getImage(): string {
    return this.modalForm.get(ControlType.Image)?.value;
  }

  public correctAmount(event: any): void {
    var value: string = event.detail.value;

    var amountsizeMapping = this.formControls.filter(c => c.type === ControlType.AmountSize)[0];
    if (!amountsizeMapping) { return; }
    var amountControl = this.modalForm.controls[amountsizeMapping.amountsizeOptions.amountControl];

    if (isNaN(parseFloat(value))) {
      amountControl.setErrors({ 'incorrect': true });
    } else {
      this.calculate();
    }
  }

  public correctVat(event: any): void {
    var value: string = event.detail.value;
    var replacedValue = value.replace('-', '').replace('+', '');

    var amountsizeMapping = this.formControls.filter(c => c.type === ControlType.AmountSize)[0];
    var vatControl = this.modalForm.controls[amountsizeMapping.amountsizeOptions.vatControl];

    if (isNaN(parseFloat(replacedValue))) {
      vatControl.setErrors({ 'incorrect': true });
    } else {
      this.calculate();
    }
  }

  public calculate(): void {
    if (this.modalForm.contains(ControlType.AmountSize) && this.formControls) {
      var amountsizeControl = this.modalForm.controls[ControlType.AmountSize];
      var amountsizeMapping = this.formControls.filter(c => c.type === ControlType.AmountSize)[0];
      var amountControl = this.modalForm.controls[amountsizeMapping.amountsizeOptions.amountControl];
      var vatControl = this.modalForm.controls[amountsizeMapping.amountsizeOptions.vatControl];

      let vatrate = 0;
      let amount = 0;
      let currency = 'EUR';

      let netAmount = 0;
      let grossAmount = 0;
      let vatAmount = 0;

      if (this.currency !== undefined) {
        currency = this.currency;
      }

      amount = amountControl.value ? parseFloat(amountControl.value.toString().replace(',', '.')) : 0;
      vatrate = vatControl.value ? (parseFloat(vatControl.value.toString().replace(',', '.')) / 100) : 0;

      if (amountsizeControl.value === 'gross') {
        netAmount = amount / (1 + vatrate);
        grossAmount = amount;
        vatAmount = amount * vatrate / (1 + vatrate);
      } else {
        netAmount = amount;
        grossAmount = amount + (amount * vatrate);
        vatAmount = amount * vatrate;
      }

      this.net = new Intl.NumberFormat(this.language, { style: 'currency', currency }).format(netAmount < 0 ? netAmount * -1 : netAmount);
      this.gross = new Intl.NumberFormat(this.language, { style: 'currency', currency }).format(grossAmount < 0 ? grossAmount * -1 : grossAmount);
      this.vat = new Intl.NumberFormat(this.language, { style: 'currency', currency }).format(vatAmount < 0 ? vatAmount * -1 : vatAmount);

      this.handleAggregations();
    } else {
      this.handleAggregations();
      return;
    }
  }

  public getReplacedValue(item: any, value: string): string {
    return Utils.getReplacedValue(item, value);
  }

  public dateChanged(event: CustomEvent, id: string): void {
    if (event.detail.value === undefined) {
      var element = document.getElementById('dateModal_' + id);
      if (element) {
        (element as HTMLIonModalElement).dismiss();
      }
    }
  }

  public getFormattedString(control: FormAttributeControl): string {
    var formControl = this.modalForm.get(control.key);

    if (!formControl || !formControl.value) {
      return undefined;
    }

    if (typeof formControl.value === 'object') {
      return;
    }

    if (control.options.type === InputType.Date) {
      let date = new Date(formControl.value);
      return format(date, 'dd.MM.yyyy', { locale: DateHelpers.getLocale(this.language) });
    } else if (control.options.type === InputType.Datetime) {
      let date = new Date(formControl.value);
      return format(date, 'dd.MM.yyyy, HH:mm', { locale: DateHelpers.getLocale(this.language) });
    } else if (control.options.type === InputType.Month) {
      let date = new Date(formControl.value);
      return format(date, 'MMMM yyyy', { locale: DateHelpers.getLocale(this.language) });
    } else if (control.options.type === InputType.Year) {
      let date = new Date(formControl.value);
      return format(date, 'yyyy', { locale: DateHelpers.getLocale(this.language) });
    } else if (control.options.type === InputType.Time) {
      var hours = formControl.value.substring(0, 2);
      var minutes = formControl.value.substring(3, 5);
      let date = new Date(null, null, null, hours, minutes);
      return format(date, 'HH:mm', { locale: DateHelpers.getLocale(this.language) });
    } else {
      return formControl.value;
    }
  }

  public getMaxDate(control: FormAttributeControl): string {
    if (control.dateOptions?.maxAddDays !== undefined && control.dateOptions?.maxAddDays !== null) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();
      const maxDate = new Date(currentYear, currentMonth, currentDay + control.dateOptions.maxAddDays);
      if (control.options.type === InputType.Date) {
        return DateHelpers.getCalenderPickerDate(maxDate);
      } else if (control.options.type === InputType.Datetime) {
        return DateHelpers.getCalenderPickerDatetime(maxDate);
      } else if (control.options.type === InputType.Month) {
        return DateHelpers.getCalenderPickerMonthYear(maxDate);
      } else if (control.options.type === InputType.Year) {
        return DateHelpers.getCalenderPickerYear(maxDate);
      } else {
        return DateHelpers.getCalenderPickerTime(maxDate);
      }
    } else {
      return '2100';
    }
  }

  public getMinDate(control: FormAttributeControl): string {
    if (control.dateOptions?.maxMinusDays !== undefined && control.dateOptions?.maxMinusDays !== null) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();
      const minDate = new Date(currentYear, currentMonth, currentDay - control.dateOptions.maxMinusDays);
      if (control.options.type === InputType.Date) {
        return DateHelpers.getCalenderPickerDate(minDate);
      } else if (control.options.type === InputType.Datetime) {
        return DateHelpers.getCalenderPickerDatetime(minDate);
      } else if (control.options.type === InputType.Month) {
        return DateHelpers.getCalenderPickerMonthYear(minDate);
      } else if (control.options.type === InputType.Year) {
        return DateHelpers.getCalenderPickerYear(minDate);
      } else {
        return DateHelpers.getCalenderPickerTime(minDate);
      }
    } else {
      return '1900';
    }
  }

  public handleAggregations(): void {
    var sumControls = this.formControls.filter((f: FormAttributeControl) => f.sumOptions);
    var countControls = this.formControls.filter((f: FormAttributeControl) => f.countOptions);
    var calcControls = this.formControls.filter((f: FormAttributeControl) => f.type === ControlType.Calc);
    if (sumControls || countControls || calcControls) {
      if (sumControls) {
        sumControls.forEach((sumControl: FormAttributeControl) => {
          var sum = this.sum(sumControl);
          this.modalForm.controls[sumControl.key].setValue(sum);
        });
      }
      if (countControls) {
        countControls.forEach((countControl: FormAttributeControl) => {
          var count = this.count(countControl);
          this.modalForm.controls[countControl.key].setValue(count);
        });
      }
      if (calcControls) {
        calcControls.forEach((calcControl: FormAttributeControl) => {
          let replacedRule = Utils.getReplacedCalcRule(this.modalForm.controls, calcControl.rule);
          let resultObj = this.parser.parse(replacedRule);
          let result = resultObj.result;
          if (calcControl.options.type === InputType.Amount) {
            result = AmountHelpers.getFormattedAmountByLanguageByCurrency(result, this.currency, this.language);
          } else {
            result = AmountHelpers.getFormattedAmountByLanguage(result, this.language, calcControl.options.minimumFractionDigits, calcControl.options.maximumFractionDigits);
          }
          this.modalForm.controls[calcControl.key].setValue(result);
        });
      }
    } else {
      return;
    }
  }

  public async openDateTimeModal(): Promise<void> {
    var element = document.getElementsByTagName('ion-datetime');
    if (element[0]) {
      var wrapperOpen = await Utils.getChildElement(element[0].shadowRoot.childNodes, 2);
      var button = await Utils.getChildElement(wrapperOpen.childNodes, 1);
      button.addEventListener('click', async () => {
        await this.adjustPosition();
      });
    } else {
      setTimeout(async () => {
        await this.openDateTimeModal();
      }, 10);
    }
  }

  public getDynamicIdentifier(prefix: string, key: string): string {
    return Utils.getDynamicIdentifier(prefix, key);
  }

  private sum(control: FormAttributeControl): string {
    var sum = 0;
    if (control.sumOptions.controls?.length > 0) {
      control.sumOptions.controls.forEach((control: string) => {
        if (this.modalForm.controls[control] && this.modalForm.controls[control].value) {
          sum += parseFloat(this.modalForm.controls[control].value.replace(',', '.'));
        }
      });
      return control.sumOptions.type && control.sumOptions.type === InputType.Number
        ? AmountHelpers.getFormattedAmountByLanguage(sum, this.language, control.sumOptions.minimumFractionDigits, control.sumOptions.maximumFractionDigits)
        : AmountHelpers.getFormattedAmountByLanguageByCurrency(sum, this.currency, this.language);
    } else {
      let sourceControl = control.sumOptions.control;
      let sourceProperty = control.sumOptions.property;
      if (this.modalForm.controls[sourceControl] && this.modalForm.controls[sourceControl].value) {
        this.modalForm.controls[sourceControl].value.forEach((element: any) => {
          if (sourceProperty.includes('<')) {
            let amountsize = element['amountsize'] ? element['amountsize'] : '';
            let calculatedAmount = 0;
            if (sourceProperty.toLowerCase().includes('netamount')) {
              calculatedAmount = AmountHelpers.calculateNetAmount(amountsize, this.currency, element['amount'], element['vatrate'], this.language, false);
            } else if (sourceProperty.toLowerCase().includes('vatamount')) {
              calculatedAmount = AmountHelpers.calculateVat(amountsize, this.currency, element['amount'], element['vatrate'], this.language, false);
            } else if (sourceProperty.toLowerCase().includes('totalamount')) {
              calculatedAmount = AmountHelpers.calculateTotal(amountsize, this.currency, element['amount'], element['vatrate'], this.language, false);
            }
            sum += calculatedAmount;
          } else {
            if (element[sourceProperty]) {
              sum += parseFloat(typeof element[sourceProperty] == 'string' ? element[sourceProperty].replace(',', '.') : element[sourceProperty].toString().replace(',', '.'));
            }
          }
        });
        return control.sumOptions.type && control.sumOptions.type === InputType.Number
          ? AmountHelpers.getFormattedAmountByLanguage(sum, this.language, control.sumOptions.minimumFractionDigits, control.sumOptions.maximumFractionDigits)
          : AmountHelpers.getFormattedAmountByLanguageByCurrency(sum, this.currency, this.language);
      } else {
        return control.sumOptions.type && control.sumOptions.type === InputType.Number
          ? AmountHelpers.getFormattedAmountByLanguage(0, this.language, control.sumOptions.minimumFractionDigits, control.sumOptions.maximumFractionDigits)
          : AmountHelpers.getFormattedAmountByLanguageByCurrency(0, this.currency, this.language);
      }
    }
  }

  private count(control: FormAttributeControl): string {
    var sourceControl = control.countOptions.control;
    if (this.modalForm.controls[sourceControl] && this.modalForm.controls[sourceControl].value) {
      return this.modalForm.controls[sourceControl].value.length;
    } else {
      return '0';
    }
  }

  private async showImage(): Promise<void> {
    // Image is URL
    var image = this.modalForm.get(ControlType.Image).value;
    if (image.includes('https://')) {
      var loading = await this.loadingCtrl.create({});
      await loading.present();
      this.imageService.getImageFromUrl(image).then(async imageBytes => {
        if (imageBytes !== null) {
          if (!this.platform.is('capacitor')) {
            if (loading) {
              await loading.dismiss();
            }
            const modalOptions: ModalOptions = {
              component: ImageModalComponent,
              componentProps: {
                image: imageBytes
              },
              cssClass: 'auto-size'
            };
            var modal = await this.modalCtrl.create(modalOptions);
            await modal.present();
          } else {
            if (loading) {
              await loading.dismiss();
            }
            const opt: capShowOptions = {} as capShowOptions;
            opt.images = [
              { url: imageBytes, title: '' }
            ];
            opt.mode = "one";
            PhotoViewer.show(opt);
          }
        } else {
          if (loading) {
            await loading.dismiss();
          }
        }
      }).catch(async () => {
        if (loading) {
          await loading.dismiss();
        }
      });
    } else {
      // Else image is base64
      if (!this.platform.is('capacitor')) {
        const modalOptions: ModalOptions = {
          component: ImageModalComponent,
          componentProps: {
            image
          },
          cssClass: 'auto-size'
        };
        var modal = await this.modalCtrl.create(modalOptions);
        await modal.present();

      } else {
        const opt: capShowOptions = {} as capShowOptions;
        opt.images = [
          { url: image, title: '' }
        ];
        opt.mode = "one";
        PhotoViewer.show(opt);
      }
    }
  }

  private createControls(): void {
    if (!this.formControls) {
      this.formControls = new Array<FormAttributeControl>();
      this.controlMapping.modalControls.forEach((control: FormAttributeControl) => {
        this.formControls.push(control);
        const newFormControl = new UntypedFormControl();
        if (control.required) {
          newFormControl.setValidators(Validators.required);
        }
        if (control.type === ControlType.Input && (control.options.type === InputType.Number || control.options.type === InputType.Amount)) {
          var existingUserProperty = Object.keys(this.authService.currentUser).filter(k => k.toLowerCase() === control.key.toLowerCase()).pop();
          if (existingUserProperty) {
            newFormControl.setValue(this.authService.currentUser[existingUserProperty]);
          }
        } else if (control.type === ControlType.AmountSize) {
          newFormControl.setValue('gross');
        } else if (control.type === ControlType.Input
          && (control.options.type === InputType.Date || control.options.type === InputType.Datetime || control.options.type === InputType.Month
            || control.options.type === InputType.Year || control.options.type === InputType.Time)
          && control.dateOptions && control.dateOptions.todayAsInitial
        ) {
          var initialDate = new Date();
          if (control.dateOptions?.addDays) {
            initialDate.setDate(initialDate.getDate() + control.dateOptions.addDays);
          }
          if (control.options.type === InputType.Date) {
            newFormControl.setValue(DateHelpers.getCalenderPickerDate(initialDate));
          } else if (control.options.type === InputType.Datetime) {
            newFormControl.setValue(DateHelpers.getCalenderPickerDatetime(initialDate));
          } else if (control.options.type === InputType.Month) {
            newFormControl.setValue(DateHelpers.getCalenderPickerMonthYear(initialDate));
          } else if (control.options.type === InputType.Year) {
            newFormControl.setValue(DateHelpers.getCalenderPickerYear(initialDate));
          } else {
            newFormControl.setValue(DateHelpers.getCalenderPickerTime(initialDate));
          }
        } else if (control.type === ControlType.Select) {
          if (control.selectOptions.value) {
            newFormControl.setValue(control.selectOptions.value);
          } else if (!control.selectOptions.itemsVariable) {
            newFormControl.setValue(control.selectOptions.items[0].value);
          }
        }

        this.modalForm.addControl(control.key, newFormControl);
      });
      var imageControl = new UntypedFormControl();
      imageControl.setValue(undefined);
      this.modalForm.addControl(ControlType.Image, imageControl);
    }
  }

  private showMaxImageAlert(): void {
    let header: string, messageMax: string, serviceButton: string, ok: string;
    this.translate.get('OK').subscribe((result: string) => ok = result);
    this.translate.get('HAN_EDI_MAX_TITLE').subscribe((result: string) => header = result);
    this.translate.get('HAN_EDI_MAX_MESSAGE_DEFECTS').subscribe((result: string) => messageMax = result);
    messageMax = messageMax.replace('$max', this.maxImages.toString());
    this.translate.get('HAN_EDI_MAX_BUTTON').subscribe((result: string) => serviceButton = result);
    this.alertCtrl.create({
      id: AlertId.MaxImages,
      header,
      message: messageMax,
      buttons: [
        {
          text: serviceButton,
          cssClass: 'alertServiceButtonBold',
          handler: async () => {
            var value = await this.storageService.get('language');
            var url = '';

            if (value === null || value !== null && value === 'de_DE') {
              url = 'https://thumbify.de/de/leistungen/';
            } else {
              url = 'https://thumbify.de/en/services/';
            }

            if (this.platform.is('capacitor') && this.platform.ready()) {
              await Browser.open({ url });
            } else {
              var popupWindow = window.open(url);
              try {
                popupWindow.focus();
              } catch {
                let header: string, message: string, ok: string;
                this.translate.get('AUT_LOG_ERR_POPUPBLOCKED_TITLE').subscribe((result: string) => header = result);
                this.translate.get('AUT_LOG_ERR_POPUPBLOCKED_MESSAGE').subscribe((result: string) => message = result);
                this.translate.get('OK').subscribe((result: string) => ok = result);
                var alert = await this.alertCtrl.create({
                  id: AlertId.PopupBlocked,
                  header,
                  message,
                  buttons: [
                    {
                      text: ok
                    }
                  ]
                });
                await alert.present();
              }
            }
          }
        },
        {
          text: ok,
          cssClass: 'alertServiceButtonNormal'
        }
      ]
    }).then(alert => {
      alert.present();
    });
  }

  private async adjustPosition(): Promise<void> {
    var element = document.getElementsByTagName('ion-popover');
    if (element[0]) {
      var contentStyle: CSSStyleDeclaration = element[0]['style'];
      var ionApp = document.querySelector("ion-app");
      var body = document.querySelector("body");
      let difference = body.clientWidth - ionApp.clientWidth;
      let offsetX = difference > 0 && difference < 350 ? (difference / 1.5 + 50) : (difference / 1.5);
      contentStyle.cssText += '--offset-x:-' + offsetX + 'px;';
    } else {
      setTimeout(async () => {
        await this.adjustPosition();
      }, 10);
    }
  }
}
