import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(
    private storage: Storage,
    private platform: Platform
  ) {
    this.init();
  }

  init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    this.platform.ready().then(async () => {
      const storage = await this.storage.create();
      await storage.clear();
      this._storage = storage;
    });
  }

  public async set(key: string, value: any): Promise<void> {
    await this._storage?.set(key, value);
  }

  public async get(key: string): Promise<any> {
    return this._storage?.get(key);
  }

  public async remove(key: string): Promise<void> {
    await this._storage.remove(key);
  }
}
