// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-select {
  max-width: 100%;
  width: 100%;
}

.item-select ion-label {
  max-width: 75px;
  min-width: 75px;
}

#select-option {
  margin-right: auto;
  width: 100%;
}

ion-item {
  --border-width: 0px !important;
}

ion-item-divider {
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: transparent !important;
  height: 38px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImZpbHRlci1kYXNoYm9hcmQtcG9wb3Zlci5wYWdlLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFBO0VBQ0EsV0FBQTtBQUNGOztBQUNBO0VBQ0UsZUFBQTtFQUNBLGVBQUE7QUFFRjs7QUFBQTtFQUNFLGtCQUFBO0VBQ0EsV0FBQTtBQUdGOztBQURBO0VBQ0UsOEJBQUE7QUFJRjs7QUFGQTtFQUNFLGVBQUE7RUFDQSxtQkFBQTtFQUNBLHlCQUFBO0VBQ0Esd0NBQUE7RUFDQSxZQUFBO0FBS0YiLCJmaWxlIjoiZmlsdGVyLWRhc2hib2FyZC1wb3BvdmVyLnBhZ2Uuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImlvbi1zZWxlY3Qge1xuICBtYXgtd2lkdGg6IDEwMCU7XG4gIHdpZHRoOiAxMDAlO1xufVxuLml0ZW0tc2VsZWN0IGlvbi1sYWJlbCB7XG4gIG1heC13aWR0aDogNzVweDtcbiAgbWluLXdpZHRoOiA3NXB4O1xufVxuI3NlbGVjdC1vcHRpb24ge1xuICBtYXJnaW4tcmlnaHQ6IGF1dG87XG4gIHdpZHRoOiAxMDAlO1xufVxuaW9uLWl0ZW0ge1xuICAtLWJvcmRlci13aWR0aDogMHB4ICFpbXBvcnRhbnQ7XG59XG5pb24taXRlbS1kaXZpZGVyIHtcbiAgZm9udC1zaXplOiAxMnB4O1xuICBsZXR0ZXItc3BhY2luZzogMXB4O1xuICB0ZXh0LXRyYW5zZm9ybTogdXBwZXJjYXNlO1xuICBiYWNrZ3JvdW5kLWNvbG9yOiB0cmFuc3BhcmVudCAhaW1wb3J0YW50O1xuICBoZWlnaHQ6IDM4cHg7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/pages/general/filter-dashboard-popover/filter-dashboard-popover.page.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;AACF;;AACA;EACE,eAAA;EACA,eAAA;AAEF;;AAAA;EACE,kBAAA;EACA,WAAA;AAGF;;AADA;EACE,8BAAA;AAIF;;AAFA;EACE,eAAA;EACA,mBAAA;EACA,yBAAA;EACA,wCAAA;EACA,YAAA;AAKF;AACA,w/BAAw/B","sourcesContent":["ion-select {\n  max-width: 100%;\n  width: 100%;\n}\n.item-select ion-label {\n  max-width: 75px;\n  min-width: 75px;\n}\n#select-option {\n  margin-right: auto;\n  width: 100%;\n}\nion-item {\n  --border-width: 0px !important;\n}\nion-item-divider {\n  font-size: 12px;\n  letter-spacing: 1px;\n  text-transform: uppercase;\n  background-color: transparent !important;\n  height: 38px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
