// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itemInput {
  border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));
}

ion-list[inset=true][lines=none] ion-item {
  --border-width: 0 !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxvY2F0aW9uLXJlY2lwaWVudC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGtIQUFBO0FBQ0o7O0FBQ0E7RUFDSSw0QkFBQTtBQUVKIiwiZmlsZSI6ImxvY2F0aW9uLXJlY2lwaWVudC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5pdGVtSW5wdXQge1xuICAgIGJvcmRlci1ib3R0b206IDFweCBzb2xpZCB2YXIoLS1pb24taXRlbS1ib3JkZXItY29sb3IsIHZhcigtLWlvbi1ib3JkZXItY29sb3IsIHZhcigtLWlvbi1jb2xvci1zdGVwLTI1MCwgI2M4YzdjYykpKTtcbn1cbmlvbi1saXN0W2luc2V0PVwidHJ1ZVwiXVtsaW5lcz1cIm5vbmVcIl0gaW9uLWl0ZW0ge1xuICAgIC0tYm9yZGVyLXdpZHRoOiAwICFpbXBvcnRhbnQ7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/pages/signature/location-recipient/location-recipient.component.scss"],"names":[],"mappings":"AAAA;EACI,kHAAA;AACJ;;AACA;EACI,4BAAA;AAEJ;AACA,wmBAAwmB","sourcesContent":[".itemInput {\n    border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));\n}\nion-list[inset=\"true\"][lines=\"none\"] ion-item {\n    --border-width: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
